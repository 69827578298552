import { AnimatedSprite, Container, Graphics, Point, Rectangle, Sprite } from 'pixi.js';
import { Eventify } from '../../../Shared/Eventify';
import { RoundState } from '../../../Shared/Types';
import { BetBoardEntry } from '../Objects';
import ObjectPool from '../utils/ObjectPool';
import SuperMap from '../utils/SuperMap';
import { vh, vw } from '../utils/dimensions';
import { calculatePointOnBezierCurve, clamp, EasingFunctions, valueToScaleClamped } from '../utils/utils';
import { Cashout } from './Cashout';
import { MOBILE_BREAKPOINT, Plot, RED_TEST } from './Plot';
import { ResourceController } from './ResourceLoader';
import Visualisation from './Visualisation';

export const AIRPLANE_SCENE_PADDING_X = -60;
export const AIRPLANE_SCENE_PADDING_Y = -40;
export const AIRPLANE_SCENE_MOBILE_PADDING_X = -50;
export const AIRPLANE_SCENE_MOBILE_PADDING_Y = -30;
export const SPEED = 5000;
export const FINAL_RADIANA = -Math.PI / 2.5;
export const AMPLITUDE_HORIZONTAL_MULTIPLIER = 0.03;
export const AMPLITUDE_VERTICAL_MULTIPLIER = 0;
export const AIRPLANE_CRASH_SPEED = 4500;
export const AIRPLANE_SCALE = 0.15;
export const AIRPLANE_SHEET_SWAP_HEIGHT = 0.2;

export const ELLIPSE_Y_OFFSET = 25;
export const MAX_PLANE_ROTATION = 30;
export const ELLIPSE_CREATION_INTERVAL = 2;
export const ELLIPSE_WIDTH_MULTIPLIER = 1.5;
export const ELLIPSE_HEIGHT_MULTIPLIER = 1.5;
export const ELLIPSE_LINE_WIDTH = 1;

const GRAPH_COLOR = 0x5bd2af;
const PLANE_X_OFFSET = 40;

interface Ellipse {
    centerX: number;
    centerY: number;
    startTime: number;
    initialWidth: number;
    initialHeight: number;
    duration: number;
    lastProgress?: number;
}

export default class AirplaneScene extends Eventify {
    container: Container = new Container();
    spiteAirplane!: AnimatedSprite;
    staticAirplane!: Sprite;
    staticAirplaneSheet!: AnimatedSprite;
    propeller!: AnimatedSprite;
    cashoutPoints!: Container<Cashout>;

    ellipseGraphic: Graphics = new Graphics();
    ellipseMask: Graphics = new Graphics();

    grCurve!: Graphics;
    border!: Graphics;

    // TODO delete later
    testingPoint!: Graphics;

    start = Date.now();
    plot: Plot;
    curveForm = [0.58, 0.2, 0.86, 0.62];
    scale = 1;
    graphLineWidth = 5;
    ellipses: Ellipse[] = [];
    lastEllipseCreationTime = Date.now();

    lastRoundEndTime = 0;
    lastRoundStartTime = 0;

    get lineWidth() {
        return this.graphLineWidth * this.scale;
    }
    range = new Rectangle(0, 0, 1, 1);

    get app() {
        return this.stage.app;
    }
    profitsPool = new ObjectPool<Cashout>({
        constructor: () => new Cashout(20000),
        reset: (o) => {
            o.visible = false;
            o.cashoutEntry = null;
            return o;
        }
    });
    constructor(public stage: Visualisation) {
        super();
        this.plot = new Plot(stage);
        this.createEllipse();

        this.container.addChild(this.plot);

        this.createCurve();
        this.createTestingPoint();
        this.createBorder();
        this.createAirplane();
        this.recalculateGraphEndPos();

        const betboard = this.app.currentBetBoard;
        const mapId2Cashout = new Map<number, Cashout>();

        this.listenTo(betboard, SuperMap.Events.add, (id: number, entry: BetBoardEntry) => {
            const cashout = this.profitsPool.getElement();
            cashout.cashoutEntry = entry;
            cashout.updateImage();
            mapId2Cashout.set(id, cashout);
            this.cashoutPoints.addChild(cashout);
        });

        this.listenTo(betboard, SuperMap.Events.remove, (id: number) => {
            const cashout = mapId2Cashout.get(id);

            if (cashout) {
                mapId2Cashout.delete(id);
                this.cashoutPoints.removeChild(cashout);
                this.profitsPool.releaseElement(cashout);
            }
        });

        this.listenTo(stage, Visualisation.Events.tick, this.onTick);
        this.listenTo(stage, 'resize', this.resize)();
    }
    createEllipse() {
        this.ellipseGraphic = new Graphics();
        this.container.addChild(this.ellipseGraphic);
        this.container.addChild(this.ellipseMask);
    }
    createNewEllipse(centerX: number, centerY: number) {
        const newEllipse: Ellipse = {
            centerX: centerX,
            centerY: centerY,
            startTime: Date.now(), // Track when this ellipse was created
            initialWidth: this.stage.width * ELLIPSE_WIDTH_MULTIPLIER,
            initialHeight: this.stage.height * ELLIPSE_HEIGHT_MULTIPLIER,
            duration: 9 // Duration of the shrinking animation for each ellipse
        };
        this.ellipses.push(newEllipse); // Add to the array
    }
    easeOutQuad(t: number): number {
        return t * (2 - t);
    }
    easeOutCubic(t: number): number {
        return 1 - Math.pow(1 - t, 3); // Cubic easing, for stronger deceleration
    }
    resetEllipses() {
        this.ellipses = [];
        this.lastEllipseCreationTime = Date.now();
        this.ellipseGraphic.clear();
    }
    drawEllipses() {
        if (this.app.state.roundState === RoundState.ROUND_ENDED) {
            return;
        } else if (this.app.state.roundState !== RoundState.ROUND_STARTED) {
            this.resetEllipses();
            return;
        }
        this.ellipseGraphic.clear();
        const currentTime = Date.now();
        for (let i = this.ellipses.length - 1; i >= 0; i--) {
            const ellipse = this.ellipses[i];
            const timeElapsed = (currentTime - ellipse.startTime) / 1000;
            const linearProgress = Math.min(timeElapsed / ellipse.duration, 1);
            const progress = this.easeOutQuad(linearProgress);
            const scale = 1 - progress;
            const scaleWidth = 1 - progress / 1.5;
            const ellipseWidth = ellipse.initialWidth * scaleWidth;
            const ellipseHeight = ellipse.initialHeight * scale;
            this.ellipseGraphic.lineStyle(ELLIPSE_LINE_WIDTH, 0xffffff, 0.15);
            this.ellipseGraphic.drawEllipse(ellipse.centerX, ellipse.centerY, ellipseWidth, ellipseHeight);
            ellipse.lastProgress = progress;
            if (progress >= 1) {
                this.ellipses.splice(i, 1);
            }
        }
    }
    createCurve() {
        this.grCurve = new Graphics();
        this.container.addChild(this.grCurve);

        this.cashoutPoints = new Container();
        this.cashoutPoints.setParent(this.container);
    }
    createBorder() {
        this.border = new Graphics();
        this.container.addChild(this.border);
    }
    drawBorder() {
        this.border.clear();
        this.border.position.set(0, 0);
        this.border.lineStyle(2, 0x5a586d, 0.2);
        this.border.drawRect(this.range.left, this.range.top, this.range.width, this.range.height);
    }
    createTestingPoint() {
        this.testingPoint = new Graphics();
        // this.container.addChild(this.testingPoint);

        this.testingPoint.beginFill(RED_TEST);
        this.testingPoint.drawRect(0, 0, 100, 1);

        // const testCashout = new Cashout(1000);
        // testCashout.visible = true;
        // testCashout.unminimize();
        // testCashout.setMultiplier(1);
        // testCashout.position.set(300, 300);
        // this.container.addChild(testCashout);
    }

    recalculateGraphEndPos() {
        const range = this.range;
        this.graphEndPos.set(range.left, range.top);
        const width = range.width;
        const height = range.height;

        this.graphEndPos.x += (width - PLANE_X_OFFSET) * Math.sin(1 * FINAL_RADIANA + Math.PI);
        this.graphEndPos.y += height * Math.cos(1 * FINAL_RADIANA + 2 * Math.PI);
    }
    createAirplane(): void {
        const t = ResourceController.resources.airplaneMain.spritesheet;
        const plane = new AnimatedSprite(t!.animations.AmigatorMain);

        plane.scale.set(AIRPLANE_SCALE);
        plane.anchor.set(0.6, 0.51);
        plane.animationSpeed = 0.4;
        this.spiteAirplane = plane;
        this.container.addChild(this.spiteAirplane);

        const res = ResourceController.resources.airplanePropeller.spritesheet;
        const planePropeller = new AnimatedSprite(res!.animations.AmigatorPropeller);
        const staticPlane = Sprite.from(ResourceController.getTexture('AmigatorPropeller'));

        this.staticAirplane = staticPlane;
        staticPlane.scale.set(AIRPLANE_SCALE);
        staticPlane.anchor.set(0.6, 0.51);
        this.container.addChild(staticPlane);

        this.propeller = planePropeller;
        planePropeller.scale.set(AIRPLANE_SCALE);
        planePropeller.animationSpeed = 0.4;
        planePropeller.anchor.set(0.6, 0.51);
        this.container.addChild(planePropeller);
        this.propeller.play();
    }

    airplaneStart = new Point();
    airplanePos = new Point();
    graphPos = new Point();
    graphPosRelative = new Point();
    graphEndPos = new Point();
    testingPointPos = new Point();

    animateAirplane() {
        if (this.app.state.roundState === RoundState.ROUND_ENDED) {
            this.lastRoundEndTime = this.app.roundEndTime;
            this.lastRoundStartTime = this.app.roundStartTime;
        }

        // if this gameround is not on, don't show graph and the plane
        if (
            this.app.state.roundState !== RoundState.ROUND_STARTED &&
            this.app.state.roundState !== RoundState.ROUND_ENDED
        ) {
            this.spiteAirplane.position.set(this.range.left, this.range.bottom);
            this.propeller.position.set(this.range.left, this.range.bottom);
            this.staticAirplane.position.set(this.range.left, this.range.bottom);
            this.spiteAirplane.visible = false; // this.app.state.roundState !== RoundState.BET_OPENED;
            this.propeller.visible = false;
            this.staticAirplane.visible = false;
            this.grCurve.clear();
            return;
        }

        // TODO check if i can use this.app.roundElapsedTime;
        /* Время от начала полета до окончания */
        const timeElapsed =
            this.app.state.roundState === RoundState.ROUND_STARTED
                ? Date.now() - this.app.roundStartTime
                : this.app.state.roundState === RoundState.ROUND_ENDED
                ? this.app.roundEndTime - this.app.roundStartTime
                : 0;

        /* Время от начала полета до текущего момента */
        const timeAfterEndElapsed =
            this.app.state.roundState === RoundState.ROUND_ENDED
                ? Date.now() - this.app.roundEndTime
                : this.app.roundEndTime;

        const timeElapsedSlowdown = -timeElapsed / SPEED;

        const range = this.range;
        const airplaneStart = this.airplaneStart.set(range.left, range.bottom);

        const airplane = this.airplanePos.set(range.left, range.top);

        const width = range.width;
        const height = range.height;

        // this take care of the lift off animation
        const scaleLift = EasingFunctions.easeInOutCubic(valueToScaleClamped(-timeElapsed / SPEED, 0, FINAL_RADIANA));

        if (scaleLift > AIRPLANE_SHEET_SWAP_HEIGHT) {
            if (!this.spiteAirplane.playing) {
                this.spiteAirplane.play();
            }
            this.propeller.visible = false;
            this.staticAirplane.visible = false;
            this.spiteAirplane.visible = true;
        } else {
            if (this.spiteAirplane.playing) {
                this.spiteAirplane.gotoAndStop(0);
            }
            this.propeller.visible = true;
            this.staticAirplane.visible = true;
            this.spiteAirplane.visible = false;
        }

        if (
            this.app.state.roundState === RoundState.ROUND_STARTED ||
            this.app.state.roundState === RoundState.ROUND_ENDED
        ) {
            airplane.x += (width - PLANE_X_OFFSET) * Math.sin(scaleLift * FINAL_RADIANA + Math.PI);
            airplane.y += height * Math.cos(scaleLift * FINAL_RADIANA + 2 * Math.PI);
        }

        this.testingPointPos.copyFrom(airplane);

        this.graphPos.copyFrom(airplane);

        // After takeoff, camera movement is simulated, makes the plane go up and down
        {
            const amplitude = new Point(
                range.height * AMPLITUDE_VERTICAL_MULTIPLIER,
                range.width * AMPLITUDE_HORIZONTAL_MULTIPLIER
            );
            const angle = scaleLift * (timeElapsedSlowdown * 5);
            airplane.x += amplitude.x * Math.cos(angle);
            airplane.y += amplitude.y * Math.sin(angle);
        }
        this.graphPosRelative.copyFrom(airplane);

        // Airplane crash movement
        if (this.app.state.roundState === RoundState.ROUND_ENDED) {
            const eased = valueToScaleClamped(timeAfterEndElapsed, 0, 1000);

            const normalX = Math.cos(this.spiteAirplane.rotation);
            const normalY = Math.sin(this.spiteAirplane.rotation);

            airplane.x += eased * normalX * AIRPLANE_CRASH_SPEED;
            airplane.y += eased * normalY * AIRPLANE_CRASH_SPEED;
        }

        // Graph, curve line
        this.drawGraph(airplaneStart, true);

        this.spiteAirplane.position.copyFrom(airplane);
        this.propeller.position.copyFrom(airplane);
        this.staticAirplane.position.copyFrom(airplane);
        const aspectCorrection = this.stage.size.height / this.stage.size.width;
        const maxRotation = (MAX_PLANE_ROTATION * Math.PI) / 180;
        const calculatedRotation = scaleLift * -Math.PI * 0.35 * aspectCorrection;
        this.spiteAirplane.rotation = clamp(calculatedRotation, -maxRotation, maxRotation);
        this.propeller.rotation = this.spiteAirplane.rotation;
        this.staticAirplane.rotation = this.spiteAirplane.rotation;
    }
    calculateNewPos(): Point {
        const range = this.range;
        const timeElapsed = this.lastRoundEndTime - this.lastRoundStartTime;
        const scaleLift = EasingFunctions.easeInOutCubic(valueToScaleClamped(-timeElapsed / SPEED, 0, FINAL_RADIANA));
        const airplane = this.airplanePos.set(range.left, range.top);
        const width = range.width;
        const height = range.height;
        airplane.x += (width - PLANE_X_OFFSET) * Math.sin(scaleLift * FINAL_RADIANA + Math.PI);
        airplane.y += height * Math.cos(scaleLift * FINAL_RADIANA + 2 * Math.PI);
        return airplane;
    }

    drawGraph(start: Point, updateProfits: boolean) {
        // Graph, curve line
        const g = this.grCurve;
        g.clear();
        g.position.set(0, 0);
        let target: Point;
        if (
            this.app.state.roundState === RoundState.ROUND_STARTED ||
            this.app.state.roundState === RoundState.ROUND_ENDED
        ) {
            target = this.graphPosRelative;
        } else {
            target = this.calculateNewPos();
        }

        // TODO if needed we can cache this once it the target reaches graphEndPos
        const cubic = this.curveForm.map((v, i) =>
            !(i % 2) ? start.x + v * (target.x - start.x) : start.y + v * (target.y - start.y)
        );

        const lineWidth = this.lineWidth;

        // Curve line
        g.lineStyle(lineWidth, GRAPH_COLOR, 1);
        g.moveTo(start.x, start.y - lineWidth / 2); // Adjust the starting point vertically
        g.bezierCurveTo(
            cubic[0],
            cubic[1] - lineWidth / 2,
            cubic[2],
            cubic[3] - lineWidth / 2,
            target.x,
            target.y - lineWidth / 2
        ); // Adjust the control points and target point vertically

        // Curve fill
        g.lineStyle(0);
        g.beginFill(GRAPH_COLOR, 0.35);
        g.moveTo(start.x, start.y); // Adjust the starting point vertically
        g.bezierCurveTo(cubic[0], cubic[1], cubic[2], cubic[3], target.x, target.y); // Adjust the control points and target point vertically
        g.lineTo(target.x, start.y); // Adjust the ending point vertically

        g.endFill();

        this.testingPoint.position.set(this.testingPointPos.x, this.testingPointPos.y);
        if (updateProfits) this.updProfits(start, cubic, target);
    }

    updProfits(start: Point, cubic: number[], target: Point) {
        const container = this.cashoutPoints;

        container.children.forEach((displayObject) => {
            // skip calculating this if it is not visible
            if (!displayObject.cashoutEntry || displayObject.cashoutEntry?.multiplier === 0) return;

            const bet = displayObject.cashoutEntry!;

            // Finding a point on the Bezier curve
            const currentMultiplier = this.app.realtimeMultiplier;
            const scale = valueToScaleClamped(bet.multiplier, 1, currentMultiplier);

            const point = calculatePointOnBezierCurve(scale, start, cubic, target);

            displayObject.position.set(point[0], point[1]);

            displayObject.setMultiplier(bet.multiplier);
            displayObject.setCashoutValue(bet.win);
            displayObject.scale.set(this.scale);

            const visible = displayObject.multiplier !== 0;
            displayObject.setVisibility(visible);
        });
    }

    onTick = () => {
        if (this.app.animationEnabled) {
            this.animateAirplane();

            const interval = ELLIPSE_CREATION_INTERVAL;
            const currentTime = Date.now();
            if (
                (currentTime - this.lastEllipseCreationTime) / 1000 >= interval &&
                this.app.state.roundState === RoundState.ROUND_STARTED
            ) {
                //console.log('creating ellipse');
                this.createNewEllipse(this.airplaneStart.x, this.airplaneStart.y + ELLIPSE_Y_OFFSET);
                this.lastEllipseCreationTime = currentTime;
            }
            this.drawEllipses();
        }
    };
    getScale() {
        const w = 6;
        const h = 9;
        const f = 0.15;
        const scale =
            this.stage.size.width / this.stage.size.height > w / h
                ? vh(f, this.stage.size.height)
                : (h * vw(f, this.stage.size.width)) / w;

        return Math.max(0.5, scale);
        //return scale;
    }
    resize = () => {
        this.scale = this.getScale();
        this.recalculateGraphEndPos();

        const isMobile = screen.width <= MOBILE_BREAKPOINT;
        const isMobileLandscape = screen.height <= MOBILE_BREAKPOINT;

        const range = this.range;
        range
            .copyFrom(this.stage.render.screen)
            .pad(
                isMobile ? AIRPLANE_SCENE_MOBILE_PADDING_X : AIRPLANE_SCENE_PADDING_X,
                isMobile || isMobileLandscape ? AIRPLANE_SCENE_MOBILE_PADDING_Y : AIRPLANE_SCENE_PADDING_Y
            );
        this.spiteAirplane.scale.set(this.scale * AIRPLANE_SCALE);
        this.propeller.scale.set(this.scale * AIRPLANE_SCALE);
        this.staticAirplane.scale.set(this.scale * AIRPLANE_SCALE);
        this.plot.plotBounds.copyFrom(this.range);
        this.plot.graphBounds.copyFrom(this.range);

        this.drawBorder();

        // resize ellipses
        this.ellipseMask.clear();
        this.ellipseMask.beginFill(0xffffff);
        this.ellipseMask.drawRect(range.left, range.top, range.width, range.height);
        this.ellipseMask.endFill();
        this.ellipseGraphic.mask = this.ellipseMask;
        this.ellipseGraphic.clear();

        for (const ellipse of this.ellipses) {
            ellipse.centerX = this.airplaneStart.x;
            ellipse.centerY = this.airplaneStart.y + ELLIPSE_Y_OFFSET;
            ellipse.initialWidth = this.stage.width * ELLIPSE_WIDTH_MULTIPLIER;
            ellipse.initialHeight = this.stage.height * ELLIPSE_HEIGHT_MULTIPLIER;
            const progress = ellipse.lastProgress || 0;
            const scale = 1 - progress;
            const scaleWidth = 1 - progress / 1.5;
            const ellipseWidth = ellipse.initialWidth * scaleWidth;
            const ellipseHeight = ellipse.initialHeight * scale;
            this.ellipseGraphic.lineStyle(ELLIPSE_LINE_WIDTH, 0xffffff, 0.15);
            this.ellipseGraphic.drawEllipse(ellipse.centerX, ellipse.centerY, ellipseWidth, ellipseHeight);
            ellipse.lastProgress = progress;
        }

        const airplane = this.airplaneStart.set(range.left, range.bottom);
        this.drawGraph(airplane, true);
        this.animateAirplane();
    };
}
