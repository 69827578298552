import { BitmapText, Container, Graphics, Point, Rectangle } from 'pixi.js';
import { EventifyMixin } from '../../../Shared/Eventify';
import { RoundState } from '../../../Shared/Types';
import { calculatePointOnBezierCurve, valueToScale } from '../utils/utils';
import { AIRPLANE_SCENE_PADDING_X, AIRPLANE_SCENE_PADDING_Y } from './AirplaneScene';
import { Marker } from './Marker';
import Visualisation from './Visualisation';

export const RED_TEST = 0x6d5858;
export const MOBILE_BREAKPOINT = 600;
export const TICK_COLOR = 0x716f89;
const SMALL_TICK_COLOR = 0x5a586d;
const TICK_Y_TOP_BOUNDARY = 45;
const CrashSpeed = 0.00006;

export class Plot extends EventifyMixin(Container) {
    plotBounds = new Rectangle(0, 0, 300, 400);
    graphBounds = new Rectangle(0, 0, 300, 400);
    xTickWidth = 10;
    xLabelIndex = 0;
    yTickWidth = 15;
    yLabelIndex = 1;
    isMobile = false;
    isLarge = false;

    textGraphics = new Container();
    gameContainer = new Container();
    labelsContainer = new Container();
    yAxisMarker: Marker;
    profitMarkers = new Container<Marker>();

    mainLine = new Graphics();
    xGraphics = new Graphics();
    yGraphics = new Graphics();

    yTickWasHighlighted = false;
    // xLabels: Text[] = [];
    // yLabels: Text[] = [];
    xLabels: BitmapText[] = [];
    yLabels: BitmapText[] = [];
    range = new Rectangle(0, 0, 1, 1);

    xAxis = 0;
    yAxisMultiplier = 0; //tick

    planeY = 0; // TODO incorrect naming, should change later

    multiplier = 0;
    finalElapsed = 0;
    finalMultiplier = 0;

    get plotWidth() {
        return this.plotBounds.width;
    }
    get plotHeight() {
        return this.plotBounds.height;
    }

    plotOffsetX = 50;
    plotOffsetY = 40;

    get graphWidth() {
        return this.graphBounds.width;
    }
    get graphHeight() {
        return this.graphBounds.height;
    }

    xIncrement = 0;
    yIncrement = 0;
    elapsedTime = 0;
    yAxisMarkerWidthTarget = 0;

    yAxisMinimum = -1;
    defaultYAxisMultiplier = 1.5;
    elapsedOffset = 0;
    xAxisMinimum = 2000;

    windowWidth = 0;

    constructor(public stage: Visualisation) {
        super();
        this.yAxisMarker = new Marker(stage, this);

        const posY = 0;
        this.yAxisMarker.targetY = posY + this.yAxisMarker.tooltipHeight / 2;

        this.onCreate();

        this.listenTo(this.stage, Visualisation.Events.tick, () => {
            this.tick();
        });

        this.listenTo(this.stage, 'resize', () => {
            this.onResize();
        });
    }

    get app() {
        return this.stage.app;
    }

    getSize(t: number) {
        let e = Math.sqrt(this.width * this.width + this.height * this.height) / 1800;
        if (e > 1) {
            e = 1;
        } else if (e < 0.4) {
            e = 0.4;
        }
        return t * e;
    }

    stepValuesX(totalSteps: number, stepMultiplier: number = 5, stepIncrement: number = 2): number {
        console.assert(Number.isFinite(totalSteps));
        let stepSize = 0.4;
        let stepValue = 0.1;

        while (true) {
            if (totalSteps < stepSize) {
                return stepValue;
            }
            stepValue *= stepIncrement;
            if (totalSteps < (stepSize *= stepMultiplier)) {
                return stepValue;
            }
            stepSize *= stepIncrement;
            stepValue *= stepMultiplier;
        }
    }

    drawX() {
        // const elapsedTime = this.getElapsedTime();
        const xAxis = this.xAxis;
        const stepVals = this.stepValuesX(xAxis, 5, 2);

        const stepWidth = this.plotBounds.width / (xAxis / stepVals);

        this.xLabelIndex = 0;
        this.xGraphics.clear();

        for (
            let stepCount = 0, elapsedTime = 0, labelIndex = 0;
            elapsedTime < xAxis;
            elapsedTime += stepVals, labelIndex++, stepCount++
        ) {
            if (stepCount > 0) {
                const elapsedTimeSeconds = elapsedTime / 1000;

                const xPos = this.plotBounds.x + (stepCount === 0 ? 4 : labelIndex * stepWidth);
                const yPos = this.plotBounds.bottom;

                if (this.xGraphics.line.alpha !== 0.25) {
                    this.xGraphics.lineStyle(2, TICK_COLOR, 0.25);
                }

                this.updateXLabel(`${elapsedTimeSeconds.toFixed(0)}s`, xPos);
                this.xGraphics.moveTo(xPos, yPos - this.xTickWidth / 2);
                this.xGraphics.lineTo(xPos, yPos + this.xTickWidth - this.xTickWidth / 2);
            }

            if (stepCount > 100) {
                break;
            }
        }

        for (let f = this.xLabelIndex + 1; f < this.xLabels.length; f++) {
            this.xLabels[f].visible &&= false;
        }
    }

    onResize() {
        const { screen } = this.stage.render;

        this.isMobile = screen.width <= MOBILE_BREAKPOINT;
        this.isLarge = screen.width > 800;
        this.windowWidth = screen.width;

        // update y axis labels
        this.yLabels.forEach((a) => {
            a.x = this.plotBounds.x + this.plotBounds.width + 5;
        });

        // this is the 1.00x label
        this.yLabels[0].y = this.plotBounds.bottom;
        this.yLabels[0].visible = true;

        // update x axis labels
        this.xLabels.forEach((a) => {
            a.y = this.plotBounds.bottom + 5;
        });

        // if (this.yAxisMarker) {
        //     this.yAxisMarker.x = this.plotBounds.x;
        //     this.yAxisMarker.onResize();
        // }

        this.profitMarkers.children.forEach((a) => {
            a.x = this.plotBounds.x;
            a.onResize();
        });

        this.range.copyFrom(screen).pad(AIRPLANE_SCENE_PADDING_X, AIRPLANE_SCENE_PADDING_Y);
    }

    updProfits() {
        function roundToNearestTree(n: number): number {
            return 3 * Math.ceil(n / 3);
        }

        const currentSize = this.profitMarkers.children.length;
        const targetSize = this.stage.app.bets.size;

        if (this.profitMarkers.children.length < 2) {
            for (let i = currentSize; i < targetSize; i++) {
                const marker = new Marker(this.stage, this, 0x5da955);
                // marker.visible = false;
                marker.visible = true;
                marker.x = this.plotBounds.x;
                marker.onResize();
                this.profitMarkers.addChild(marker);
            }
        }

        // remove markers that are not needed - does not really happen at this point
        for (let i = currentSize - 1; i >= roundToNearestTree(targetSize); i--) {
            this.profitMarkers.removeChildAt(i);
        }

        const profitsVisible =
            this.stage.app.state.roundState === RoundState.ROUND_STARTED ||
            this.stage.app.state.roundState === RoundState.ROUND_ENDED;

        let i = 0;

        for (const bet of this.stage.app.bets) {
            const marker = this.profitMarkers.children[i++];
            const shouldBeVisible = bet.state.cashedOut && profitsVisible;

            if (!marker.visible && shouldBeVisible) {
                marker.visible = true;
                marker.setTargetMultiplier(bet.state.winMultiplier || 1);
            } else if (marker.visible && !shouldBeVisible) {
                marker.visible = false;
            }

            if (marker.visible) {
                marker.tick();
            }
        }
    }

    updateXLabel(t: string, xPos: number) {
        this.xLabelIndex += 1;
        if (this.xLabelIndex >= this.xLabels.length) {
            return false;
        }
        const r = this.xLabels[this.xLabelIndex];
        if (r.text !== t) {
            r.text = t;
        }
        if (r.x !== xPos) {
            r.x = xPos;
        }
        r.visible ||= true;
        return r;
    }
    updateYLabel(t: string, yPos: number) {
        this.yLabelIndex += 1;
        if (this.yLabelIndex >= this.yLabels.length) {
            return false;
        }

        const r = this.yLabels[this.yLabelIndex];
        if (r.text !== t) {
            r.text = t;
        }
        if (r.y !== yPos) {
            r.y = yPos;
            r.x = this.plotBounds.x + this.plotBounds.width + 5;
        }
        r.visible ||= true;

        return r;
    }

    stepValuesY(multiplier: number): number {
        if (multiplier < 10) return 1;
        if (multiplier < 50) return 5;
        return 10;
    }

    getYTickY = (tickValue: number, start: Point, cubic: number[], target: Point) => {
        const scale = valueToScale(tickValue, 1, this.yAxisMultiplier);
        const adjustedScale = scale > 2 ? 2 : scale;
        const point = calculatePointOnBezierCurve(adjustedScale, start, cubic, target);

        return point[1];
    };

    drawY() {
        let currentMultiplier = this.yAxisMultiplier;
        const plotWidth = this.plotBounds.width;
        let multiplier = this.multiplier;

        if (
            this.app.state.roundState === RoundState.BET_OPENED ||
            this.app.state.roundState === RoundState.BET_CLOSED
        ) {
            currentMultiplier = 1;
            multiplier = 1;
        }

        const plotHeight = this.plotBounds.height;

        // this says how many points will one multiplier take, 1x - 2x will take tickYRange
        const stepValue = this.stepValuesY(multiplier || 1);

        this.yTickWasHighlighted = false;
        this.yLabelIndex = 0;
        this.yGraphics.clear();

        let tickValue = stepValue;

        const start = this.stage.airplaneScene.airplaneStart;
        const target = this.stage.airplaneScene.graphEndPos;

        const cubic = this.stage.airplaneScene.curveForm.map((v, i) =>
            !(i % 2) ? start.x + v * (target.x - start.x) : start.y + v * (target.y - start.y)
        );

        //  + stepValue tick marks were missing
        const tickValueLimit = currentMultiplier * 1.75;

        for (; tickValue < tickValueLimit; tickValue += stepValue) {
            // tick position
            const tickX = this.plotBounds.x + plotWidth;

            const tickY = this.getYTickY(tickValue, start, cubic, target);

            if (this.yGraphics.line.alpha !== 0.25) {
                this.yGraphics.lineStyle(2, TICK_COLOR, 0.25);
            }

            // if tick is out of bounds, hide it
            if (tickY < TICK_Y_TOP_BOUNDARY) {
                this.yGraphics.lineStyle(2, TICK_COLOR, 0);
            }

            // tick line drawing
            this.yGraphics.moveTo(tickX - this.yTickWidth, tickY);
            this.yGraphics.lineTo(tickX, tickY);

            // label
            const label = this.updateYLabel(`${tickValue.toFixed(2)}x`, tickY);

            if (label) {
                label.tint = tickY < TICK_Y_TOP_BOUNDARY ? 0x000000 : 0xffffff;
            }

            if (!this.isMobile) {
                const subTickCount = 10;

                if (
                    this.app.state.roundState === RoundState.BET_OPENED ||
                    this.app.state.roundState === RoundState.BET_CLOSED
                ) {
                    break;
                }
                this.drawYSubTicks(tickX, subTickCount, tickValue, stepValue, cubic);
            }

            // if they are out of bounds, stop drawing
            if (tickY < 0) {
                break;
            }
        }

        // 1.00x tick
        this.yGraphics.lineStyle(2, TICK_COLOR, 0.25);
        this.yGraphics.moveTo(this.plotBounds.x + 0.5 + ~~plotWidth - this.yTickWidth, this.plotBounds.y + plotHeight);
        this.yGraphics.lineTo(this.plotBounds.x + 0.5 + ~~plotWidth, this.plotBounds.y + plotHeight);

        if (!this.yTickWasHighlighted) {
            this.yAxisMarkerWidthTarget = 0;
        }

        for (let j = this.yLabelIndex + 1; j < this.yLabels.length; j++) {
            this.yLabels[j].visible &&= false;
        }
    }

    drawYSubTicks(tickX: number, count: number, tickValue: number, stepValue: number, cubic: number[]) {
        const miniStepValue = stepValue / count;
        const start = this.stage.airplaneScene.airplaneStart;
        const target = this.stage.airplaneScene.graphEndPos;

        for (let o = 1; o < count; o++) {
            const isHalf = o === count / 2;
            const subTickLength = isHalf ? 12 : 7;
            const subTickPosition = this.getYTickY(tickValue + o * miniStepValue, start, cubic, target);

            if (isHalf) {
                if (this.yGraphics.line.alpha !== 0.3) {
                    this.yGraphics.lineStyle(2, SMALL_TICK_COLOR, 0.3);
                }
            } else if (this.yGraphics.line.alpha !== 0.2) {
                this.yGraphics.lineStyle(2, SMALL_TICK_COLOR, 0.2);
            }

            // hide if above graph
            if (subTickPosition < TICK_Y_TOP_BOUNDARY) {
                this.yGraphics.lineStyle(2, SMALL_TICK_COLOR, 0);
            }

            this.yGraphics.moveTo(tickX - subTickLength, subTickPosition);
            this.yGraphics.lineTo(tickX, subTickPosition);
        }
    }
    onCreate() {
        this.xLabels = Array.from(
            {
                length: 20
            },
            () => {
                const text = new BitmapText('0s', {
                    fontName: 'AxisFont',
                    tint: 0xffffff
                });
                text.visible = false;
                text.anchor.set(0.5, 0);
                this.labelsContainer.addChild(text);
                return text;
            }
        );
        this.yLabels = Array.from(
            {
                length: 20
            },
            (t, r) => {
                const i = new BitmapText(r === 0 ? '1.00x' : '', {
                    fontName: 'AxisFont',
                    tint: 0xffffff
                });

                i.visible = false;
                i.anchor.set(0, 0.5);
                // i.y = 100;
                this.labelsContainer.addChild(i);
                return i;
            }
        );

        if (!this.isMobile) {
            this.yAxisMarker;
        }

        this.gameContainer.addChild(
            this.xGraphics,
            this.yGraphics,
            this.labelsContainer,
            this.mainLine,
            this.profitMarkers
        );

        if (this.yAxisMarker) {
            this.gameContainer.addChild(this.yAxisMarker);
        }
        this.addChild(this.gameContainer);
    }

    getYMultiplier(position: number) {
        return (
            Math.ceil((this.yAxisMultiplier - (position / this.plotHeight) * this.yAxisMultiplier + 1) * 1000) / 1000
        );
    }
    getElapsedTime() {
        if (this.app.state.roundState === RoundState.ROUND_ENDED) {
            return this.finalElapsed;
        } else if (this.app.state.roundState !== RoundState.ROUND_STARTED) {
            return 0;
        } else {
            // TODO doublecheck this
            // return (this.finalElapsed = Date.now() - this.app.roundStartTime);
            return (this.finalElapsed = this.app.roundElapsedTime);
        }
    }

    getElapsedPayout(time: number) {
        return this.app.time2multiplier(time).toNumber();

        const r = ~~(Math.pow(Math.E, CrashSpeed * time) * 100) / 100;
        if (!Number.isFinite(r)) {
            throw new Error('Infinite payout');
        }
        return Math.max(r, 1);
    }

    getElapsedPosition(mp: number, time = mp) {
        const r = mp - 1;

        return {
            x: time * this.xIncrement,
            y: this.plotBounds.height - r * this.yIncrement
        };
    }
    tick() {
        if (this.stage.app.realtimeMultiplier <= 0 || this.stage.app.realtimeMultiplier === Infinity) {
            return;
        }

        this.elapsedTime = this.stage.app.multiplier2time(this.stage.app.realtimeMultiplier).toNumber(); //this.getElapsedTime();

        if (
            this.app.state.roundState === RoundState.BET_OPENED ||
            this.app.state.roundState === RoundState.BET_CLOSED
        ) {
            this.elapsedTime = 0;
        }

        this.multiplier =
            this.app.state.roundState === RoundState.ROUND_ENDED
                ? this.getElapsedPayout(this.elapsedTime)
                : this.app.realtimeMultiplier;

        this.yAxisMinimum = this.defaultYAxisMultiplier;
        this.yAxisMultiplier = this.defaultYAxisMultiplier;

        this.xAxis = Math.max(this.elapsedTime + this.elapsedOffset, this.xAxisMinimum);

        // TODO check this if it really happens and when
        // if (this.multiplier * 1.45 > this.yAxisMinimum) {
        //     this.yAxis = this.multiplier * 1.45; //  here you can set the easing
        // }
        // if (this.multiplier > this.yAxisMinimum) {
        //     this.yAxis = this.multiplier; //  here you can set the easing
        // }

        // no easing for now
        this.yAxisMultiplier = this.multiplier;

        this.xIncrement = this.plotBounds.width / this.xAxis;
        this.yIncrement = this.plotBounds.height / this.yAxisMultiplier;

        // draw X,Y with their units
        this.drawX();
        this.drawY();

        // TODO can delete later imo
        // if (this.yAxisMarker && this.yAxisMarker.visible) {
        //     this.yAxisMarker.setTargetMultiplier(this.app.realtimeMultiplier);
        //     // this.yAxisMarker.setTargetMultiplier(1.5);
        //     this.yAxisMarker.tick();
        // }

        this.updProfits();
    }
}
