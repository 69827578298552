import { Dialog, RadioGroup } from '@headlessui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Bet from '../../Bet';
import BetInput from '../BetPanel/BetInput';
import { errorToast } from '../Notifications/NotificationsPanel';
import { appContext } from '../contexts';
import Button from '../other/Button';
import Checkbox from '../other/Checkbox';
import { DialogContext } from '../other/Dialog';
import { useEventify } from '../utils';

export default function ({ bet }: { bet: Bet }) {
    const { close } = useContext(DialogContext);
    const app = useContext(appContext);
    const { t } = useTranslation();

    const [state, setState] = useState({ value: 10 });

    //const forceUpdate = ((a: Function) => a(Symbol())).bind(null, useState<symbol>()[1]);

    const forceUpdate = () => {
        setState((prev) => {
            return { ...prev };
        });
    };

    useEventify((eventify) => {
        eventify.listenTo(bet.autoplay, 'change:rounds', forceUpdate);
        eventify.listenTo(bet.autoplay, 'change:*', forceUpdate);
    });

    function startAutoplay() {
        if (bet.autoplay.rounds === 0) {
            errorToast({ message: 'You must select number of rounds' });
            return;
        }
        if (
            !bet.autoplay.stopIfCashIncreases &&
            !bet.autoplay.stopIfCashDecreases &&
            !bet.autoplay.stopIfSingleWinExceeds &&
            app.config.autoplay.requiredStopConditions
        ) {
            errorToast({ message: 'You must enable at least one stop condition' });
            return;
        }
        if (
            (bet.autoplay.stopIfCashIncreases && bet.autoplay.stopIfCashIncreasesBy === 0) ||
            (bet.autoplay.stopIfCashDecreases && bet.autoplay.stopIfCashDecreasesBy === 0) ||
            (bet.autoplay.stopIfSingleWinExceeds &&
                bet.autoplay.stopIfSingleWinExceedsValue === 0 &&
                app.config.autoplay.requiredStopConditions)
        ) {
            errorToast({ message: 'You must set at least one stop condition value' });
            return;
        }
        bet.startAutoPlay();
        close?.();
    }

    function resetAutoplay() {
        bet.resetAutoPlay();
    }

    return (
        <>
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 modal-header">
                {t('modalAutoplay.title')}
            </Dialog.Title>

            <div className="flex flex-col gap-2 w-full bg-modal-gray-dark p-2.5">
                <RadioGroup
                    value={bet.autoplay.rounds}
                    onChange={(value) => {
                        bet.autoplay.rounds = value;
                    }}
                    className="bg-modal-gray-light rounded-lg p-2"
                >
                    <RadioGroup.Label className="block w-full text-center">
                        {t('modalAutoplay.numberOfRounds')}:
                    </RadioGroup.Label>
                    <div className="flex flex-row justify-center py-2 gap-x-2">
                        {app.config.autoplay.rounds.map((v, i) => (
                            <RadioGroup.Option key={i} value={v}>
                                {({ checked }) => (
                                    <Button
                                        children={v}
                                        key={i}
                                        className={
                                            'flex !rounded-full bg-modal-gray-dark border-modal-gray-light ' +
                                            (checked ? '!border-green-600 !text-white !bg-[#545b62]' : '')
                                        }
                                    ></Button>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>

                <AutoplayOption
                    name={t('modalAutoplay.stopIfCashDecreasesBy')}
                    value={bet.autoplay.stopIfCashDecreasesBy}
                    enabled={bet.autoplay.stopIfCashDecreases}
                    onChange={(v) => (bet.autoplay.stopIfCashDecreasesBy = v)}
                    onCheck={(activated) => {
                        bet.autoplay.stopIfCashDecreases = activated;
                    }}
                />

                <AutoplayOption
                    name={t('modalAutoplay.stopIfCashIncreasesBy')}
                    value={bet.autoplay.stopIfCashIncreasesBy}
                    enabled={bet.autoplay.stopIfCashIncreases}
                    onChange={(v) => (bet.autoplay.stopIfCashIncreasesBy = v)}
                    onCheck={(activated) => (bet.autoplay.stopIfCashIncreases = activated)}
                />

                <AutoplayOption
                    name={t('modalAutoplay.stopIfSingleWinExceeds')}
                    value={bet.autoplay.stopIfSingleWinExceedsValue}
                    enabled={bet.autoplay.stopIfSingleWinExceeds}
                    onChange={(v) => (bet.autoplay.stopIfSingleWinExceedsValue = v)}
                    onCheck={(activated) => (bet.autoplay.stopIfSingleWinExceeds = activated)}
                    onMount={(setValue) => {
                        useEventify((eventify) => {
                            eventify.listenTo(bet.autoplay, 'change:stopIfSingleWinExceedsValue', setValue);
                        });
                    }}
                />
            </div>
            <div className="bottom-bar">
                <div className="flex justify-end h-fit items-center">
                    <Button onClick={resetAutoplay} secondary className="">
                        {t('modalAutoplay.resetButton')}
                    </Button>
                </div>
                <div className="flex justify-end">
                    <Button
                        className={`flex flex-grow flex-col justify-center items-center rounded-3xl w-full min-w-[120px] `}
                        greenButton
                        onClick={startAutoplay}
                    >
                        {t('modalAutoplay.startButton')}
                    </Button>
                </div>
            </div>
        </>
    );
}

interface IAutoplayOption {
    name: string;
    value: number;
    enabled: boolean;
    onChange: (value: number) => void;
    onCheck: (enabled: boolean) => void;
    onMount?: (setValue: (value: number) => void) => void;
}

function AutoplayOption({ name, value, enabled, onChange, onCheck, onMount = () => {} }: IAutoplayOption) {
    const app = useContext(appContext);

    const opacity = enabled ? '' : ' opacity-50 ';

    return (
        <div className="flex w-full items-center justify-between p-3 gap-2 bg-modal-gray-light rounded-lg ">
            <div className="flex flex-row flex-grow gap-2 items-center">
                <div className="">
                    <Checkbox
                        checked={enabled}
                        onChange={(checked) => {
                            onCheck(checked);
                        }}
                    ></Checkbox>
                </div>
                <div className={'flex-grow-1' + opacity}>{name}</div>
            </div>
            <div className="mx-1">
                <BetInput defaultValue={value} min={0} enabled={enabled} onChange={onChange} onMount={onMount} />
            </div>
            <div className={'mx-1' + opacity}>{app.config.bet.code}</div>
        </div>
    );
}
