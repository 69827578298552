import { Tab } from '@headlessui/react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServerMessageType } from '../../../../Shared/MessageTypes';
import { adjustValueByPrecision } from '../../../../Shared/utils';
import Bet from '../../Bet';
import { clamp, money } from '../../utils/utils';
import { appContext, betContext } from '../contexts';
import { useEventify } from '../utils';
import BetAutoplay from './BetAutoplay';
import BetButtons from './BetButtons';
import BetInput from './BetInput';
import './BetPanel.scss';

export default function BetPanel({
    bet,
    multipleBetPanels = false,
    firstBetPanel = false,
    lastBetPanel = false,
    secondPanelVisible = false,
    onClickShowBetPanel,
    onClickHideBetPanel
}: {
    bet: Bet;
    multipleBetPanels: boolean;
    firstBetPanel: boolean;
    lastBetPanel: boolean;
    secondPanelVisible?: boolean;
    onClickShowBetPanel: () => void;
    onClickHideBetPanel: () => void;
}) {
    const app = useContext(appContext);
    const { t } = useTranslation();
    const [lastHotBet, setLastHotBet] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);

    function setBetValue(value: number) {
        if (bet.state.setted || bet.state.waitBetOpen) return;
        bet.state.value = clamp(value, app.config.bet.min, app.config.bet.max);
    }

    function increaseBetByValue(value: number) {
        const v = lastHotBet === value ? bet.state.value + value : value;
        setLastHotBet(value);
        setBetValue(v);
    }

    // const setAutoEnabled = (bool: boolean) => (bet.state.autoCashoutEnabled = bool);
    function borderClass(): ` ${string}` {
        switch (true) {
            case bet.ui.cancelVisible:
                return ' border-red-600';
            case bet.ui.cashOutVisible:
                return ' border-yellow-600';
        }
        return ' border-transparent';
    }

    const forceUpdate = ((a: Function) => a(Symbol())).bind(null, useState<symbol>()[1]);

    useEventify((eventify) => {
        eventify.listenTo(app.network, ServerMessageType.config, forceUpdate);
        eventify.listenTo(bet.ui, 'change:cancelVisible', 'change:cashOutVisible', forceUpdate);
    });

    if (bet.state.visible === false) return null;
    return (
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <betContext.Provider value={bet}>
                <div
                    className={
                        'bet-panel relative flex flex-col flex-grow md:flex-grow-0 w-full p-1 gap-1 rounded-xl panel-bg border-solid border max-w-full h-[170px] lg:h-[230px]' +
                        borderClass()
                    }
                >
                    {/* FIRST PANEL SHOW BUTTON */}
                    {multipleBetPanels && firstBetPanel && !secondPanelVisible && (
                        <button
                            onClick={onClickShowBetPanel}
                            type="button"
                            className=" bet-panel-show hover:bg-green-500 bg-green-600 text-xl"
                        >
                            <div className="relative w-full h-full">
                                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[2px] w-[60%] bg-[#fefce8]" />
                                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[2px] w-[60%] bg-[#fefce8] rotate-90" />
                            </div>
                        </button>
                    )}

                    {/* LAST PANEL HIDE BUTTON */}
                    {multipleBetPanels && lastBetPanel && (
                        <button
                            onClick={onClickHideBetPanel}
                            type="button"
                            className="bet-panel-show hover:bg-[#484848] bg-[#303030] text-xl"
                        >
                            <div className="h-[2px] w-[60%] bg-[#fefce8]" />
                        </button>
                    )}

                    {/* TABS */}
                    <Tab.List className="relative flex justify-center my-0 lg:my-3 w-full max-w-[400px] m-auto">
                        <div className="flex flex-row w-8/12 rounded-full text-sm px-0.5 py-0.5 gap-0.5 bg-[#27272A] overflow-hidden">
                            <div
                                className="absolute z-10 top-0 bottom-0 rounded-full bg-[#3F3F46] transition-transform duration-300"
                                style={{
                                    width: '35%',
                                    transform: `translateX(${selectedIndex * 94.2 -3.9}%)` }}
                            ></div>
                            <Tab
                                key={0}
                                as="button"
                                className={({ selected }) => `tab-item ${selected && 'active-tab-item'}`}
                            >
                                {t('betPanel.bet')}
                            </Tab>
                            <Tab
                                key={2}
                                as="button"
                                className={({ selected }) => `tab-item ${selected && 'active-tab-item'}`}
                            >
                                {t('betPanel.auto')}
                            </Tab>
                        </div>
                    </Tab.List>
                    <div className="max-w-[400px] m-auto w-full">
                        {/* BET SELECT & BIG BUTTON */}
                        <div className="flex flex-row place-content-stretch justify-center items-center px-2 gap-x-2 w-full m-0 mx-auto mt-auto mb-auto h-full max-h-[96px] lg:max-h-[102px]">
                            <div className="flex flex-col items-center w-5/12 md:w-4/12 lg:w-5/12 xl:w-4/12 py-1">
                                {/* BET + / - */}
                                <BetInput
                                    defaultValue={bet.state.value}
                                    enabled={bet.ui.buttonsEnabled}
                                    onChange={(value) => {
                                        setBetValue(value);
                                    }}
                                    onMount={(setValue) => {
                                        useEventify((eventify) => {
                                            eventify.listenTo(bet.state, 'change:value', setValue);
                                        });
                                    }}
                                    min={adjustValueByPrecision(app.config.bet.min, app.config)}
                                    max={adjustValueByPrecision(app.config.bet.max, app.config)}
                                />
                                {/* HOT BET */}
                                <div className="grid grid-cols-2 gap-2 w-full mt-2 text-[#a8a29e]">
                                    {app.config.bet.fastBets.map((x, i) => (
                                        <button
                                            key={i}
                                            disabled={!bet.ui.buttonsEnabled}
                                            onMouseDown={() => increaseBetByValue(x)}
                                            className="flex justify-center rounded-lg w-full disabled:opacity-40 text-sm lg:text-base"
                                            style={{ background: '#000000b3' }}
                                        >
                                            {money(x, app.config.bet, true)}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-start w-7/12 md:w-8/12 lg:w-7/12 xl:w-8/12 h-full">
                                <BetButtons bet={bet} />
                            </div>
                        </div>
                        <Tab.Panels>
                            <Tab.Panel key={1}>{/* placeholder */}</Tab.Panel>
                            <Tab.Panel key={2}>
                                {/* AUTO PLAY PANEL */}
                                <BetAutoplay />
                            </Tab.Panel>
                        </Tab.Panels>
                    </div>
                </div>
            </betContext.Provider>
        </Tab.Group>
    );
}
