import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { roundPrecission } from '../../../../Shared/utils';
import { config } from '../../config';
import { clamp } from '../../utils/utils';
import ModalAutoplay from '../Modals/ModalAutoplay';
import { betContext } from '../contexts';
import Checkbox from '../other/Checkbox';
import { DialogComponent } from '../other/Dialog';
import { useEventify } from '../utils';

export default function BetAutoplay() {
    const { t } = useTranslation();
    const setAutoCashout = (bool: boolean) => (bet.state.autoCashoutEnabled = bool);
    const bet = useContext(betContext);
    const buttonsEnabled = bet.ui.buttonsEnabled && bet.state.autoCashoutEnabled;

    const forceUpdate = ((a: Function) => a(Symbol())).bind(null, useState<symbol>()[1]);
    useEventify((eventify) => {
        eventify.listenTo(bet.state, 'change:autoPlayVisible', forceUpdate);
        eventify.listenTo(bet.state, 'change:autoCashoutEnabled', () => {
            forceUpdate();
        });
        eventify.listenTo(bet.state, 'change:autoCashOutValue', (v: number) => {
            onInput(undefined, String(v));
        });
        eventify.listenTo(bet.ui, 'change:buttonsEnabled', forceUpdate);
    });

    const [formatter] = useState(
        () => new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    );
    const refInput = useRef<HTMLInputElement>(null);
    const isMouseDown = useRef(false);
    const isFocused = useRef(false);

    function onInput(target = refInput.current!, _value = target.value) {
        const value = parseFloat(_value);
        if (Number.isNaN(value)) return;
        const number = clamp(parseFloat(target.value), parseFloat(target.min), parseFloat(target.max));
        target.value = formatter.format(number).replace(/,/g, '.');
        bet.state.autoCashOutValue = roundPrecission(number, 2);
    }

    return (
        <div className="flex justify-center items-center text-sm gap-1 md:gap-2 p-2 h-6 lg:h-8 mt-2">
            <div className="flex flex-shrink-0 max-w-full">
                <BetAutoplayButton />
            </div>
            <div className="flex flex-grow"></div>
            <label className="flex flex-shrink-0 flex-nowrap gap-2">
                <div className={'flex-shrink-0 text-sm ' + (!bet.ui.buttonsEnabled ? 'opacity-50' : '')}>
                    {t('betPanel.autoCashOut')}
                </div>
                <Checkbox
                    disabled={!bet.ui.buttonsEnabled}
                    checked={bet.state.autoCashoutEnabled}
                    onChange={setAutoCashout}
                />
            </label>

            <div
                className="flex flex-row font-bold bg-black data-[disabled=true]:opacity-40 rounded-full relative"
                data-disabled={!buttonsEnabled}
            >
                <input
                    ref={refInput}
                    disabled={!buttonsEnabled}
                    className={
                        'bg-transparent flex w-16 text-center flex-row p-1 px-2 rounded-full disabled:select-none disabled:pointer-events-none'
                    }
                    type="number"
                    min={config.autoplay.minAutoCashOutMultiplier}
                    max={config.autoplay.maxAutoCashOutMultiplier}
                    step={0.01}
                    defaultValue={bet.state.autoCashOutValue.toFixed(2)}
                    onInput={() => isMouseDown.current && onInput()}
                    onBlur={() => ((isFocused.current = false), onInput())}
                    onMouseUp={() => (isMouseDown.current = false)}
                    onMouseDown={() => (isMouseDown.current = true)}
                    onFocus={() => (isFocused.current = true)}
                    onKeyDown={(e) => {
                        if (['Enter', 'Escape'].includes(e.key)) refInput.current!.blur();
                    }}
                />
                <div className="flex flex-row absolute right-0 h-full p-1 px-2 pointer-events-none">x</div>
            </div>
        </div>
    );
}

function BetAutoplayButton() {
    const bet = useContext(betContext);
    const state = useState(false);
    const [_isHelpOpen, setHelpOpen] = state;
    const { t } = useTranslation();

    return (
        <>
            {bet.autoplay.roundsLeft > 0 ? (
                <button
                    onClick={bet.cancelAutoPlay.bind(bet)}
                    className="flex justify-center rounded-full text-sm px-2 md:px-4 py-0.5 border-solid bg-red-500 w-full whitespace-nowrap"
                >
                    {t('betPanel.stop')} ({bet.autoplay.roundsLeft})
                </button>
            ) : (
                <button
                    onClick={setHelpOpen.bind(null, true)}
                    className="flex justify-center rounded-full text-sm px-2 md:px-4 py-0.5 border-solid bg-blue-500 w-full whitespace-nowrap"
                >
                    {t('betPanel.autoPlay')}
                </button>
            )}

            <DialogComponent state={state}>
                <ModalAutoplay bet={bet}></ModalAutoplay>
            </DialogComponent>
        </>
    );
}
